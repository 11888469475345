import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
// import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
// import HeroBanner from "../../components/HeroBanner";
// import TabsComponent from "../../components/TabsComponent";
// import { publicationsTabsState } from "../../Recoil/atoms";

const Default = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi({
      url: AppData.BASE_URL + "api/v1/notifications/job",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          setNotifications(result.data.data);
        }
      },
    });
  }, []);

  return (
    <div className="recruitment-container ">
      <HeroBanner name="Recruitment" />

      <div className="content-wrapper " style={{ minHeight: "30vw" }}>
        {notifications &&
          notifications.map((noti, key) => {
            return (
              <div key={key} className="notification">
                <div className="title">{noti.n_title}</div>
                <div className="description">{noti.n_description}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Default;
