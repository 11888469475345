import React, { useState } from "react";
import { TabsState, TabDataState } from "../Recoil/atoms";
import { useRecoilState } from "recoil";

const TwoTabs = (props) => {
  // const [activeTabData, setActiveTabData] = useState(props.tabs[0]);

  const onTabClick = (tab) => {
    let newTabs = [];

    props.tabs &&
      props.tabs.length > 0 &&
      props.tabs.map((oldTab) => {
        if (oldTab.id === tab.id) {
          newTabs.push({ ...oldTab, active: true });
        } else {
          newTabs.push({ ...oldTab, active: false });
        }
      });
    props.setTabsState(newTabs);
  };

  return (
    <div className="tabs-and-data two-tabs">
      <div className="tabs-container ">
        {props.tabs &&
          props.tabs.length > 0 &&
          props.tabs.map((tab, key) => {
            return (
              <div
                className={tab.active ? "tab active-tab" : "tab"}
                key={key}
                onClick={() => onTabClick(tab)}
              >
                <span className="font10 bold-text">{tab.name}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TwoTabs;
