import React, { useEffect, useState } from "react";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import SingularTabs from "../../components/SingularTabs";
import { FaCalendar } from "react-icons/fa";
import { callApi } from "../../api/callApi";
import MyModal from "../../components/MyModal";

const Default = () => {
  const [tabs, setTabs] = useState([
    { id: 0, name: "Workshops", active: true },
    { id: 1, name: "Trainig Programmes", active: false },
    { id: 2, name: "Interstate Visits", active: false },
    { id: 3, name: "Stakeholders Meet", active: false },
  ]);

  const [workshops, setWorkshops] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [visits, setVisits] = useState([]);
  const [collections, setCollections] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    let workshops = [];
    let programmes = [];
    let visits = [];
    let collections = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/cms/activities",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          // setTabsData(result.data.data);

          result.data.data.map((val) => {
            switch (val.section_title) {
              case "Workshops":
                workshops.push(val);
                break;
              case "Trainig Programmes":
                programmes.push(val);
                break;
              case "Interstate Visits":
                visits.push(val);
                break;
              case "Data Collected":
                collections.push(val);
                break;
              default:
                break;
            }
          });

          setWorkshops(workshops);
          setProgrammes(programmes);
          setVisits(visits);
          setCollections(collections);
        }
      },
    });
  }, []);

  const renderActivityItem = (activity, key) => {
    return (
      <div className="activity-item" key={key}>
        <div className="img-container">
          <img
            src={AppData.BASE_URL + "upload/cms/" + activity.attachment}
            alt="icon"
          />
        </div>
        <div className="date-time">
          <FaCalendar fontSize={18} />
          <span className="date">{activity.updated_at.split("T")[0]}</span>
        </div>
        <div className="title bold-text">{activity.title}</div>
        <div
          className="content description-text"
          dangerouslySetInnerHTML={{
            __html: activity?.description?.slice(0, 500),
          }}
        ></div>
        <div
          className="read-more-activities"
          onClick={() => {
            setShowModal(true);
            setCurrentItem(activity);
          }}
        >
          <span>Read More</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <HeroBanner name="Activities" />

      <div className="rest-page content-wrapper">
        <SingularTabs tabs={tabs} setTabsState={setTabs} />

        <div>
          {tabs[0].active ? (
            <div className="activities-container">
              {workshops &&
                workshops.map((workshop, key) => {
                  return renderActivityItem(workshop, key);
                })}
            </div>
          ) : null}

          {tabs[1].active ? (
            <div className="activities-container">
              {programmes &&
                programmes.map((programme, key) => {
                  return renderActivityItem(programme, key);
                })}
            </div>
          ) : null}

          {tabs[2].active ? (
            <div className="activities-container">
              {visits &&
                visits.map((visit, key) => {
                  return renderActivityItem(visit, key);
                })}
            </div>
          ) : null}

          {tabs[3].active ? (
            <div className="activities-container">
              {collections &&
                collections.map((collection, key) => {
                  return renderActivityItem(collection, key);
                })}
            </div>
          ) : null}
        </div>
      </div>

      <MyModal
        open={showModal}
        title={currentItem && currentItem.title}
        desc={currentItem && currentItem.description}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default Default;
