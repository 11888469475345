import React, { useEffect, useMemo, useState } from "react";
import HeroBanner from "../../components/HeroBanner";
import MyTable from "../../components/MyTable";
import SingularTabs from "../../components/SingularTabs";
import TwoTabs from "../../components/TwoTabs";
import apis from "./apis";

const Default = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const lineDepts = useMemo(() => apis.getLineDepts(), [true]);
  const institutions = useMemo(() => apis.getInstitutions(), []);
  const { clusterFarmers, individualFarmers } = useMemo(
    () => apis.getFarmers(),
    []
  );
  const collectors = useMemo(() => apis.getCollectors(), []);
  const shgs = useMemo(() => apis.getSHGS(), []);
  const ngos = useMemo(() => apis.getNGOS(), []);
  const traders = useMemo(() => apis.getTraders(), []);
  const fpos = useMemo(() => apis.getFPOS(), []);
  const stakeholder_intro = useMemo(() => apis.getIntro(), []);

  const [tabs, setTabs] = useState([
    {
      id: 0,
      name: "Line Departments",
      active: true,
    },
    {
      id: 1,
      name: "Institutions",
      active: false,
    },
    {
      id: 2,
      name: "Farmers",
      active: false,
    },
    {
      id: 3,
      name: "Collectors",
      active: false,
    },
    {
      id: 4,
      name: "SHG'S",
      active: false,
    },
    {
      id: 5,
      name: "NGO'S",
      active: false,
    },
    {
      id: 6,
      name: "Traders",
      active: false,
    },
    {
      id: 7,
      name: "FPO'S",
      active: false,
    },
  ]);

  const [lineDeptTabs, setLineDeptTabs] = useState([
    { id: 0, name: "State", active: true },
    { id: 1, name: "National", active: false },
  ]);

  const [instTabs, setInstTabs] = useState([
    { id: 0, name: "State", active: true },
    { id: 1, name: "National", active: false },
  ]);

  const [farmerTabs, setFarmerTabs] = useState([
    { id: 0, name: "Individual", active: true },
    { id: 1, name: "Cluster", active: false },
  ]);

  const farmerColumns = useMemo(
    () => [
      {
        accessorKey: "district",
        header: "District",
      },
      {
        accessorKey: "farmers",
        header: "No. of Farmers",
      },
      {
        accessorKey: "species",
        header: "Name of MP Species under Cultivation",
      },
      {
        accessorKey: "land",
        header: "Land under Cultivation in Acres ",
      },
    ],
    []
  );

  const collectorColums = useMemo(
    () => [
      {
        accessorKey: "district",
        header: "District",
      },
      {
        accessorKey: "collectors",
        header: "No. of Farmers",
      },
      {
        accessorKey: "species",
        header: "Number of MP Species Collectors",
      },
    ],
    []
  );

  const shgColumns = useMemo(
    () => [
      {
        accessorKey: "district",
        header: "District",
      },
      {
        accessorKey: "collectors",
        header: "No. of Farmers",
      },
      {
        accessorKey: "species",
        header: "Number of MP Species Collectors",
      },
    ],
    []
  );

  const ngoColumns = useMemo(
    () => [
      {
        accessorKey: "district",
        header: "District",
      },
      {
        accessorKey: "collectors",
        header: "No. of NGOS",
      },
    ],
    []
  );

  const traderColumns = useMemo(
    () => [
      {
        accessorKey: "district",
        header: "District",
      },
      {
        accessorKey: "name",
        header: "Name of Trading Company",
      },
    ],
    []
  );

  const fpoColumns = useMemo(
    () => [
      {
        accessorKey: "district",
        header: "District",
      },
      {
        accessorKey: "collectors",
        header: "No. of FPOS",
      },
    ],
    []
  );

  const renderLineDept = (val, key) => {
    return (
      <div className="list-item font12" key={key}>
        <div className="inner-item">
          <span className="serial">{key + 1}</span>
          <span className="name">{val.department}</span>
        </div>
      </div>
    );
  };

  //for manually refreshing the view -- simple hack as of now
  useMemo(
    () =>
      setTimeout(() => {
        setLineDeptTabs([
          { id: 0, name: "State", active: true },
          { id: 1, name: "National", active: false },
        ]);
      }, 2000),
    []
  );

  return (
    <div>
      <HeroBanner name="Stakeholders" />

      <div className="rest-page content-wrapper">
        <div className="stakeholder-description">
          <span
            dangerouslySetInnerHTML={{
              __html:
                stakeholder_intro && stakeholder_intro.length > 0
                  ? stakeholder_intro[0].data
                  : "",
            }}
            className="font11"
          />
        </div>
        <SingularTabs tabs={tabs} setTabsState={setTabs} />

        <div>
          {tabs[0].active ? (
            <div>
              <TwoTabs tabs={lineDeptTabs} setTabsState={setLineDeptTabs} />

              <div className="line-depts">
                {lineDepts &&
                  lineDepts
                    .filter((val) =>
                      lineDeptTabs[0].active
                        ? val.region === "STATE"
                        : val.region === "NATIONAL"
                    )
                    .map((val, key) => {
                      return renderLineDept(val, key);
                    })}
              </div>
            </div>
          ) : null}
        </div>

        <div>
          {tabs[1].active ? (
            <div>
              <TwoTabs tabs={instTabs} setTabsState={setInstTabs} />

              <div className="line-depts">
                {institutions
                  .filter((val) =>
                    instTabs[0].active
                      ? val.region === "STATE"
                      : val.region === "NATIONAL"
                  )
                  .map((val, key) => {
                    return renderLineDept(val, key);
                  })}
              </div>
            </div>
          ) : null}
        </div>

        <div>
          {tabs[2].active ? (
            <div>
              <TwoTabs tabs={farmerTabs} setTabsState={setFarmerTabs} />

              <div>
                <MyTable
                  columns={farmerColumns}
                  data={
                    farmerTabs[0].active ? clusterFarmers : individualFarmers
                  }
                  enableColumnResizing={true}
                  enableRowNumbers={true}
                />
              </div>
            </div>
          ) : null}
        </div>

        <div>
          {tabs[3].active ? (
            <div>
              <MyTable
                columns={collectorColums}
                data={collectors}
                enableColumnResizing={true}
                enableRowNumbers={true}
              />
            </div>
          ) : null}
        </div>

        <div>
          {tabs[4].active ? (
            <div>
              <MyTable
                columns={shgColumns}
                data={shgs}
                enableColumnResizing={true}
                enableRowNumbers={true}
              />
            </div>
          ) : null}
        </div>

        <div>
          {tabs[5].active ? (
            <div>
              <MyTable
                columns={ngoColumns}
                data={ngos}
                enableColumnResizing={true}
                enableRowNumbers={true}
              />
            </div>
          ) : null}
        </div>

        <div>
          {tabs[6].active ? (
            <div>
              <MyTable
                columns={traderColumns}
                data={traders}
                enableColumnResizing={true}
                enableRowNumbers={true}
              />
            </div>
          ) : null}
        </div>

        <div>
          {tabs[7].active ? (
            <div>
              <MyTable
                columns={fpoColumns}
                data={fpos}
                enableColumnResizing={true}
                enableRowNumbers={true}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Default;
