import { callApi } from "../../api/callApi";
import AppData from "../../AppData";

const apis = {
  getLineDepts: () => {
    var lineDepts = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/stakeholder_type_one/LINE_DEPARTMENTS",
      callback: (res) => {
        if (res && res.data && res.data.data) {
          res.data.data.forEach((val) => {
            lineDepts.push(val);
          });
        }
      },
    });
    return lineDepts;
  },

  getInstitutions: () => {
    let institutions = [];

    callApi({
      url: AppData.BASE_URL + "api/v1/stakeholder_type_one/INSTUTIONS",
      callback: (res) => {
        if (res && res.data && res.data.data) {
          res.data.data.forEach((val) => {
            institutions.push(val);
          });
        }
      },
    });
    return institutions;
  },

  getFarmers: () => {
    let clusterFarmers = [];
    let individualFarmers = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/stakeholder_type_two/FARMERS",
      callback: (res) => {
        if (res && res.data && res.data.data) {
          res.data.data.map((val) => {
            if (val.type === "CLUSTER") {
              clusterFarmers.push({
                district: val.district,
                farmers: val.count,
                species: val.species_name + `(${val.species_number})`,
                land: val.cultivation_land,
              });
            } else {
              individualFarmers.push({
                district: val.district,
                farmers: val.count,
                species: val.species_name + `(${val.species_number})`,
                land: val.cultivation_land,
              });
            }
          });
        }
      },
    });

    return {
      clusterFarmers,
      individualFarmers,
    };
  },

  getCollectors: () => {
    let collectors = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/stakeholder_type_two/COLLECTORS",
      callback: (res) => {
        if (res && res.data && res.data.data) {
          res.data.data.forEach((val) => {
            collectors.push({
              district: val.district,
              collectors: val.count,
              species: val.species_number,
            });
          });
        }
      },
    });
    return collectors;
  },

  getSHGS: () => {
    let shgs = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/stakeholder_type_two/SGH",
      callback: (res) => {
        if (res && res.data && res.data.data) {
          res.data.data.map((val) => {
            shgs.push({
              district: val.district,
              collectors: val.count,
              species: val.species_name + `(${val.species_number})`,
            });
          });
        }
      },
    });

    return shgs;
  },

  getNGOS: () => {
    let ngos = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/stakeholder_type_two/NGO",
      callback: (res) => {
        if (res && res.data && res.data.data) {
          res.data.data.map((val) => {
            ngos.push({
              district: val.district,
              collectors: val.count,
            });
          });
        }
      },
    });

    return ngos;
  },

  getTraders: () => {
    let traders = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/stakeholder_type_two/TRADERS",
      callback: (res) => {
        if (res && res.data && res.data.data) {
          res.data.data.map((val) => {
            traders.push({
              district: val.district,
              name: val.species_name,
            });
          });
        }
      },
    });

    return traders;
  },
  getFPOS: () => {
    let fpos = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/stakeholder_type_two/TRADERS",
      callback: (res) => {
        if (res && res.data && res.data.data) {
          res.data.data.map((val) => {
            fpos.push({
              district: val.district,
              collectors: val.count,
            });
          });
        }
      },
    });

    return fpos;
  },

  getIntro: () => {
    let intro = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/stakeholder_intro",
      callback: (res) => {
        if (res && res.data && res.data.data) {
          intro.push(res.data.data[0]);
        }
      },
    });
    return intro;
  },
};

export default apis;
