import React, { useState } from "react";
import MaterialReactTable from "material-react-table";

const MyTable = (props) => {
  let columns = props.columns;
  let data = props.data;

  return (
    <div style={{ marginBottom: 30 }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnOrdering //enable some features
        //   enableRowSelection
        enablePagination={true} //disable a default feature
        //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
        //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
        //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
        enableColumnResizing={props.enableColumnResizing}
        enableRowNumbers={props.enableRowNumbers}
      />
    </div>
  );
};

export default MyTable;
