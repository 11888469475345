import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";

const Default = () => {
  const [acts, setActs] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    callApi({
      url: AppData.BASE_URL + "api/v1/cms/acts_and_rules",
      method: "GET",
      callback: (result) => {
        if (result && result.data && result.data.data) {
          setActs(result.data.data);
        }
      },
    });
  }, []);

  const renderActItem = (act, key) => {
    return (
      <div className="act-list-item" key={key}>
        <div className="bold-text font14">{act.title}</div>
        <div className="description-button-container">
          <span
            className="general-text font11"
            dangerouslySetInnerHTML={{ __html: act.description }}
          ></span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <HeroBanner name="Acts and Rules" />
      <div className="content-wrapper act-list">
        {acts &&
          acts.map((act, key) => {
            return renderActItem(act, key);
          })}
      </div>
    </div>
  );
};

export default Default;
