import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import SingularTabs from "../../components/SingularTabs";
// import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
// import HeroBanner from "../../components/HeroBanner";
// import TabsComponent from "../../components/TabsComponent";
// import { publicationsTabsState } from "../../Recoil/atoms";

const Default = () => {
  const [tabs, setTabs] = useState([
    { id: 0, name: "Central Sector Schemes", active: true },
    { id: 1, name: "National AYUSH Mission", active: false },
    { id: 2, name: "Voluntary certificate scheme", active: false },
  ]);
  const [central, setCentral] = useState([]);
  const [national, setNatioal] = useState([]);
  const [voluntary, setVoluntary] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi({
      url: AppData.BASE_URL + "api/v1/cms/schemes",
      method: "GET",
      callback: (result) => {
        let centralArr = [];
        let nationalArr = [];
        let voluntaryArr = [];

        if (result && result.data && result.data.data) {
          result.data.data.map((val) => {
            switch (val.section_title) {
              case "Central Sector Schemes":
                centralArr.push(val);
                break;
              case "National AYUSH Mission":
                nationalArr.push(val);
                break;
              case "Voluntary Certificate Scheme for Medical Plant Procerdure":
                voluntaryArr.push(val);
                break;
              default:
                break;
            }
          });
          setCentral(centralArr);
          setNatioal(nationalArr);
          setVoluntary(voluntaryArr);
        }
      },
    });
  }, []);

  const renderItem = (item, key) => {
    return (
      <div className="act-list-item scheme-item" key={key}>
        <div className="bold-text font14">{item.title}</div>
        <div className="description-button-container">
          <span
            className="general-text font11"
            dangerouslySetInnerHTML={{ __html: item.description }}
          ></span>
        </div>
      </div>
    );
  };
  return (
    <div>
      <HeroBanner name="Schemes" />

      <div
        className="rest-page content-wrapper schemes"
        style={{ minHeight: "30vh" }}
      >
        <SingularTabs tabs={tabs} setTabsState={setTabs} />
        <div>
          {tabs[0].active
            ? central &&
              central.map((item, key) => {
                return renderItem(item, key);
              })
            : null}
          {tabs[1].active
            ? national &&
              national.map((item, key) => {
                return renderItem(item, key);
              })
            : null}

          {tabs[2].active
            ? voluntary &&
              voluntary.map((item, key) => {
                return renderItem(item, key);
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default Default;
