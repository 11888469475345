import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import SingularTabs from "../../components/SingularTabs";

const Default = () => {
  const [tabs, setTabs] = useState([
    { id: 0, name: "Pamphlets", active: true },
    { id: 1, name: "Brochures", active: false },
    { id: 2, name: "Booklets", active: false },
  ]);

  const [pamphlets, setPamphlets] = useState([]);
  const [brochures, setBrochures] = useState([]);
  const [booklets, setBooklets] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let pamphlets = [];
    let brochures = [];
    let booklets = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/cms/publications",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          result.data.data.map((val) => {
            switch (val.section_title) {
              case "Pamphlets":
                pamphlets.push(val);
                break;
              case "Brochures":
                brochures.push(val);
                break;
              case "Boooklets":
                booklets.push(val);
                break;
              default:
                break;
            }
          });

          setPamphlets(pamphlets);
          setBrochures(brochures);
          setBooklets(booklets);
        }
      },
    });
  }, []);

  const downloadContent = (link) => {
    window.open(link, "_blank");
  };

  const renderActivityItem = (val, key) => {
    return (
      <div className="font12 publications-item" key={key}>
        <span>{val.title}</span>
        <button
          className="dowload-button button"
          onClick={() =>
            downloadContent(AppData.BASE_URL + "upload/cms/" + val.attachment)
          }
        >
          Download
        </button>
      </div>
    );
  };

  return (
    <div>
      <HeroBanner name="Publications" />

      <div className="rest-page content-wrapper" style={{ minHeight: "30vh" }}>
        <SingularTabs tabs={tabs} setTabsState={setTabs} />

        <div className="publications-container">
          {tabs[0].active ? (
            <div className="">
              {pamphlets &&
                pamphlets.length > 0 &&
                pamphlets.map((workshop, key) => {
                  return renderActivityItem(workshop, key);
                })}
            </div>
          ) : null}

          {tabs[1].active ? (
            <div className="">
              {brochures &&
                brochures.length > 0 &&
                brochures.map((programme, key) => {
                  return renderActivityItem(programme, key);
                })}
            </div>
          ) : null}

          {tabs[2].active ? (
            <div className="">
              {booklets &&
                booklets.length > 0 &&
                booklets.map((programme, key) => {
                  return renderActivityItem(programme, key);
                })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Default;
