import "./App.css";
import React, { useEffect, useState } from "react";
import { RecoilRoot } from "recoil";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./style/style.scss";
import AboutSkuast from "./screens/AboutScreens/Default";
import PlantingDetails from "./screens/PlantingScreens/Default";
import Publications from "./screens/Publications/Default";
import Feedback from "./screens/Feedback/Default";
import Species from "./screens/Species/Default";
import Stakeholders from "./screens/Stakeholders/Default";
import Schemes from "./screens/Schemes/Default";
import OurTeam from "./screens/OurTeam/Default";
import ActsRules from "./screens/ActsRules/Default";
import Recruitment from "./screens/Recruitment/Default";
import Gallery from "./screens/Gallery/Default";
import Activities from "./screens/Activities/Default";
import ContactUs from "./screens/ContactUs/Default";
import PricingDetails from "./screens/PricingDetails/Default";

const Default = React.lazy(() => import("./screens/HomeScreen/Default.js"));
import Header from "./components/Header";
import Footer from "./components/Footer";
import PageNotFound from "./components/PageNotFound";
import { callApi } from "./api/callApi";
import AppData from "./AppData";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  const [startupData, setStartupData] = useState({});
  const [details, setDetails] = useState({});
  useEffect(() => {
    loadStartupData();
    getContactDetails();
  }, []);

  const loadStartupData = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/settings",
      method: "GET",
      callback: (result) => {
        if (result && result.data) {
          setStartupData(result.data);
        }
      },
    });
  };

  const getContactDetails = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/contacts",
      method: "GET",
      callback: (result) => {
        if (result && result.data && result.data.data) {
          setDetails(result.data.data[0]);
        }
      },
    });
  };

  return (
    <div>
      <RecoilRoot>
        {startupData &&
        startupData.data &&
        startupData.data.maintance_mode == 0 ? (
          <HashRouter>
            <React.Suspense fallback={loading}>
              <Header props={""} />
              <Switch>
                <Route
                  exact
                  path="/"
                  name="Home"
                  render={(props) => <Default {...props} />}
                />
                <Route
                  exact
                  path="/about-skuast"
                  name="AboutSkuast"
                  render={(props) => <AboutSkuast {...props} />}
                />

                <Route
                  exact
                  path="/planting-details"
                  name="Planting Details"
                  render={(props) => <PlantingDetails {...props} />}
                />
                <Route
                  exact
                  path="/publications"
                  name="Publications"
                  render={(props) => <Publications {...props} />}
                />
                <Route
                  exact
                  path="/gallery"
                  name="GALLERY"
                  render={(props) => <Gallery {...props} />}
                />

                <Route
                  exact
                  path="/activities"
                  name="Activities"
                  render={(props) => <Activities {...props} />}
                />

                <Route
                  exact
                  path="/contact-us"
                  name="Contact Us"
                  render={(props) => <ContactUs {...props} />}
                />

                <Route
                  exact
                  path="/feedback"
                  name="Feedback"
                  render={(props) => <Feedback {...props} />}
                />
                <Route
                  exact
                  path="/species"
                  name="Species"
                  render={(props) => <Species {...props} />}
                />
                <Route
                  exact
                  path="/schemes"
                  name="Schemes"
                  render={(props) => <Schemes {...props} />}
                />
                <Route
                  exact
                  path="/recruitment"
                  name="Recruitment"
                  render={(props) => <Recruitment {...props} />}
                />
                <Route
                  exact
                  path="/team"
                  name="OurTeam"
                  render={(props) => <OurTeam {...props} />}
                />
                <Route
                  exact
                  path="/stakeholders"
                  name="Stakeholders"
                  render={(props) => <Stakeholders {...props} />}
                />
                <Route
                  exact
                  path="/acts"
                  name="ActsRules"
                  render={(props) => <ActsRules {...props} />}
                />
                <Route
                  exact
                  name="Contact Us"
                  path="/contactus"
                  render={(props) => <ContactUs {...props} />}
                />

                <Route
                  exact
                  path="/pricing-details"
                  name="Pricing Details"
                  render={(props) => <PricingDetails {...props} />}
                />
                <Route component={PageNotFound} />
              </Switch>
              <Footer data={details} />
            </React.Suspense>
          </HashRouter>
        ) : (
          <div className="maintenance-wrapper">
            <div className="bold-text heading font20">
              {startupData &&
                startupData.data &&
                startupData.data.maintance_heading}
            </div>

            <div className="description fot14">
              {startupData &&
                startupData.data &&
                startupData.data.maintance_description}
            </div>
          </div>
        )}
      </RecoilRoot>
    </div>
  );
}

export default App;
