import React, { useEffect, useState } from "react";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import HeroBanner from "../../components/HeroBanner";
import TabsComponent from "../../components/TabsComponent";
import SingularTabs from "../../components/SingularTabs";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";

const Default = () => {
  const [tabs, setTabs] = useState([
    { id: 0, name: "Herbal Gardens", active: true },
    { id: 1, name: "Medicinal Plant Cultivators", active: false },
    { id: 2, name: "Medicinal Plant Sellers", active: false },
    { id: 3, name: "Medicinal Plant Traders", active: false },
    { id: 4, name: "Medicinal Plant Buyers", active: false },
    { id: 5, name: "Availability of Planting Material", active: false },
  ]);

  const [tabsData, setTabsData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi({
      url: AppData.BASE_URL + "api/v1/cms/plant_details",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          setTabsData(result.data.data);
        }
      },
    });
  }, []);

  const getDataToDisplay = (mySection) => {
    return (
      <div className="description-text">
        {tabsData &&
          tabsData.map((tab) => {
            if (tab.section_title === mySection) {
              return (
                <div
                  dangerouslySetInnerHTML={{ __html: tab.description }}
                ></div>
              );
            }
          })}
      </div>
    );
  };

  return (
    <div>
      <HeroBanner name="Plantig Details" />

      <div className="rest-page content-wrapper">
        <SingularTabs tabs={tabs} setTabsState={setTabs} />

        <div style={{ minHeight: "30vh" }}>
          {tabs[0].active ? (
            <div className="">{getDataToDisplay("Herbal Gardens")}</div>
          ) : null}

          {tabs[1].active ? (
            <div className="">
              {getDataToDisplay("Medicinal Plant Cultivators")}
            </div>
          ) : null}
          {tabs[2].active ? (
            <div className="">
              {getDataToDisplay("Medicinal Plant Sellers")}
            </div>
          ) : null}
          {tabs[3].active ? (
            <div className="">
              {getDataToDisplay("Medicinal Plant Traders")}
            </div>
          ) : null}
          {tabs[4].active ? (
            <div className="">{getDataToDisplay("Medicinal Plant Buyers")}</div>
          ) : null}

          {tabs[5].active ? (
            <div className="">
              {getDataToDisplay(
                "Availability of Quality Planting Material and seed"
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Default;
