import React from "react";
import MultilevelDropdown from "./MultilevelDropdown";
import { useHistory } from "react-router-dom";

function Header(props) {
  let history = useHistory();

  return (
    <div className="header-parent">
      <div className="logo-conntainer" onClick={() => history.push("/")}>
        <img src="/images/logo.jpg" alt="logo" />
        <div
          className="font9 white-color website-title"
          style={{ marginTop: 2 }}
        >
          <span className="font13 bold-text">
            Regional Cum Facilitation Centre Northern Region II
          </span>
          <span className="font9 line2">
            National Medicinal Plant Board, Ministry of AYUSH, Govt. of India
          </span>
          <span className="font9 line3">
            Sher-e-Kashmir University of Agricultural Sciences & Technology of
            Kashmir
          </span>
        </div>
      </div>
      <div className="links-container font15">
        <MultilevelDropdown />
      </div>
      <div className="ext-logos">
        <div className="footer-inner ">
          <div
            className="footer-item-left"
            onClick={() => window.open("https://nmpb.nic.in/", "_blank")}
          >
            <img
              className="footer-logo"
              src="/images/nmpb_logo.jpg"
              alt="logo"
            />
            {/* <div className="footer-title">
              <span>NMPB</span>
            </div> */}
          </div>
          <div
            className="footer-item-left"
            onClick={() => window.open("https://echarak.in/echarak/", "_blank")}
          >
            <img
              className="footer-logo"
              src="/images/echarak_logo.png"
              alt="logo"
            />
            {/* <div className="footer-title">
              <span>Echarak</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
