import React, { useEffect, useMemo, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import MyTable from "../../components/MyTable";
import SingularTabs from "../../components/SingularTabs";
// import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
// import HeroBanner from "../../components/HeroBanner";
// import TabsComponent from "../../components/TabsComponent";
// import { publicationsTabsState } from "../../Recoil/atoms";

const Default = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi({
      url: AppData.BASE_URL + "api/v1/cms/species",
      callback: (res) => {
        if (res && res.data && res.data.data) {
          setData(res.data.data);
        }
      },
    });
  }, []);
  const [tabs, setTabs] = useState([
    {
      id: 0,
      name: "Kashmir",
      active: true,
    },
    {
      id: 1,
      name: "Jammu",
      active: false,
    },
    {
      id: 2,
      name: "Ladakh",
      active: false,
    },
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Title",
        cellStyle: {
          whiteSpace: "nowrap",
          width: "10%",
        },
      },
      {
        accessorKey: "section_title",
        header: "Region",
        cellStyle: {
          whiteSpace: "nowrap",
          width: "10%",
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        cellStyle: {
          whiteSpace: "nowrap",
          width: "60%",
        },
      },
    ],
    []
  );
  return (
    <div className="species-container">
      <HeroBanner name="Species" />

      <div className="rest-page content-wrapper">
        <SingularTabs tabs={tabs} setTabsState={setTabs} />

        <div>
          <MyTable
            columns={columns}
            data={data.filter((val) =>
              tabs[0].active
                ? val.section_title === "Kashmir"
                : tabs[1].active
                ? val.section_title === "Jammu"
                : val.section_title === "Ladakh"
            )}
            enableColumnResizing={true}
            enableRowNumbers={true}
          />
        </div>

        {/* {tabs[0].active ?
         } */}
      </div>
    </div>
  );
};

export default Default;
