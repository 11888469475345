import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import SingularTabs from "../../components/SingularTabs";

const AboutSkuastK = () => {
  const [aboutTabs, setAboutTabs] = useState([
    { id: 0, name: "Background of RCFC NR-II", active: true },
    { id: 1, name: "Establishment of RCFC NR-II", active: false },
    { id: 2, name: "Functions of RCFC NR-II", active: false },
  ]);

  const [aboutTabsData, setAboutTabsData] = useState([]);

  useEffect(() => {
    loadAboutCMS();
    window.scrollTo(0, 0);
  }, []);

  const loadAboutCMS = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/cms/about",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          setAboutTabsData(result.data.data);
        }
      },
    });
  };

  const getDataToDisplay = (mySection) => {
    return (
      <div className="description-text">
        {aboutTabsData.map((tab) => {
          if (tab.section_title === mySection) {
            return (
              <div dangerouslySetInnerHTML={{ __html: tab.description }} />
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className="aboutskuast-k-container">
      <HeroBanner name="About RCFC NR-II" />
      <div className="rest-page content-wrapper" style={{ minHeight: "30vh" }}>
        <SingularTabs tabs={aboutTabs} setTabsState={setAboutTabs} />

        <div className="about-container">
          {aboutTabs[0].active ? (
            <div className="">
              {getDataToDisplay("Background of RCFC North II")}
            </div>
          ) : null}

          {aboutTabs[1].active ? (
            <div className="">
              {getDataToDisplay("Establishment of RCFC North II")}
            </div>
          ) : null}

          {aboutTabs[2].active ? (
            <div className="">
              <div className="" />
              {getDataToDisplay("Functions of RCFC North II")}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default AboutSkuastK;
