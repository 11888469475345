import React from "react";
import { useHistory } from "react-router-dom";
import Dropdown from "rsuite/Dropdown";
import "rsuite/dist/rsuite.min.css";
import { set } from "lodash";

const MultilevelDropdown = (props) => {
  let history = useHistory();
  const gotoScreen = (screenName) => {
    history.push(screenName);
  };
  const menu = [
    // { title: "Home", screen: "/" },
    {
      title: "About us",
      subMenu: [
        {
          id: 0,
          name: "Background of RCFC NR-II",
          screen: "/about-skuast",
        },
        {
          id: 1,
          name: "Establishment of RCFC NR-II",
          screen: "/about-skuast",
        },
        {
          id: 2,
          name: "Functions of RCFC NR-II",
          screen: "/about-skuast",
        },
      ],
    },
    {
      title: "Planting Details",
      subMenu: [
        { id: 0, name: "Herbal Gardens", screen: "/planting-details" },
        {
          id: 1,
          name: "Medicinal Plant Cultivators",
          screen: "/planting-details",
        },
        { id: 2, name: "Medicinal Plant Sellers", screen: "/planting-details" },
        {
          id: 3,
          name: "Medicinal Plant Traders",
          screen: "/planting-details",
        },
        {
          id: 4,
          name: "Medicinal Plant Buyers",
          screen: "/planting-details",
        },
        {
          id: 6,
          name: "Availability of Quality Planting Material and seed",
          screen: "/planting-details",
        },
      ],
    },

    {
      title: "Activities",
      subMenu: [
        { id: 0, name: "Workshops", screen: "/activities" },
        { id: 1, name: "Training Programmes", screen: "/activities" },
        { id: 2, name: "Interstate Visits", screen: "/activities" },
        { id: 3, name: "Stakeholders Meet", screen: "/activities" },
      ],
    },
    {
      title: "Pricing Details",
      screen: "/pricing-details",
    },
    { title: "Stakeholders", screen: "/stakeholders" },
    {
      title: "Publications",
      subMenu: [
        { id: 0, name: "Pamphlets", screen: "/publications" },
        { id: 1, name: "Brochures", screen: "/publications" },
        { id: 2, name: "Boooklets", screen: "/publications" },
      ],
    },
    {
      title: "Gallery",
      screen: "/gallery",
    },
    {
      title: "Contact us",
      screen: "/contact-us",
    },
  ];

  return (
    <div className="multi-level-dropdown-container">
      {menu.map((mainMenuItem, key) => {
        return mainMenuItem.subMenu ? (
          <Dropdown
            title={mainMenuItem.title}
            trigger="hover"
            placement="bottomStart"
            style={{ marginRight: 6 }}
            key={key}
          >
            {mainMenuItem &&
              mainMenuItem.subMenu &&
              mainMenuItem.subMenu.map((subMenuItem) => {
                return subMenuItem && subMenuItem.subMenu ? (
                  <Dropdown
                    title={subMenuItem.name}
                    trigger="hover"
                    placement="rightStart"
                    style={{ marginRight: 10 }}
                  >
                    {subMenuItem.subMenu.map((innerSubItem) => {
                      return (
                        <Dropdown.Item
                          key={innerSubItem.id}
                          onClick={() => gotoScreen(innerSubItem.screen)}
                          className="font11"
                        >
                          {innerSubItem.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown>
                ) : (
                  <Dropdown.Item
                    key={subMenuItem.id}
                    onClick={() => gotoScreen(subMenuItem.screen)}
                    className="font11"
                  >
                    {subMenuItem.name}
                  </Dropdown.Item>
                );
              })}
          </Dropdown>
        ) : (
          <div
            className="rs-dropdown"
            onClick={() =>
              mainMenuItem.screen ? gotoScreen(mainMenuItem.screen) : null
            }
            key={key}
          >
            <button className="rs-btn">{mainMenuItem.title}</button>{" "}
          </div>
        );
      })}
    </div>
  );
};

export default MultilevelDropdown;
