import React, { useMemo, useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import MyTable from "../../components/MyTable";

const Default = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi({
      url: AppData.BASE_URL + "api/v1/market",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          setItems(result.data.data);
        }
      },
    });
  }, []);

  const getItems = () => {
    return items;
  };

  const data = useMemo(() => items, [items]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //simple recommended way to define a column
        header: "Botinical Name",
        Header: () => <i>Botinical Name</i>,
        muiTableHeadCellProps: { sx: { color: "green" } }, //optional custom props
        // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      },
      {
        // accessorFn: (row) => row.age, //alternate way
        // id: "age", //id required if you use accessorFn instead of accessorKey
        accessorKey: "part",
        header: "Part",
        Header: () => <i>Part</i>, //optional custom header render
      },
      {
        // accessorFn: (row) => row.age, //alternate way
        // id: "age", //id required if you use accessorFn instead of accessorKey
        accessorKey: "price",
        header: "Price",
        Header: () => <i>Price</i>, //optional custom header render
      },
    ],
    []
  );

  return (
    <div>
      <HeroBanner name="Pricing details" />
      <div
        className="profile-card-container content-wrapper"
        style={{ minHeight: "30vh" }}
      >
        <div className="price-list-item">
          <MyTable columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
};

export default Default;
