import React from "react";
import { useHistory } from "react-router-dom";
import { FaFacebook, FaYoutube, FaTwitter } from "react-icons/fa";

function Footer(props) {
  const history = useHistory();
  const navigateRoute = (screen) => {
    history.push(screen);
  };

  return (
    <div className="footer-parent-container">
      <div className="footer-container-inner">
        <div className="footer-item">
          <div>
            <div className="footer-item-left bold-text">Location:</div>
            <div className="footer-item-left">
              {props.data && props.data.details}
            </div>
          </div>
        </div>

        <div className="footer-item">
          <div className="link">
            <span onClick={() => navigateRoute("/acts")}>Acts &amp; Rules</span>
          </div>
          <div className="link">
            <span onClick={() => navigateRoute("/team")}>Our Team</span>
          </div>
          <div className="link">
            <span onClick={() => navigateRoute("/schemes")}>Schemes</span>
          </div>
        </div>
        <div className="footer-item">
          <div className="link">
            <span onClick={() => navigateRoute("/recruitment")}>
              Recruitment
            </span>
          </div>
          <div className="link">
            <span onClick={() => navigateRoute("/species")}>Species</span>
          </div>
          <div className="link">
            <span onClick={() => navigateRoute("/feedback")}>Feedback</span>
          </div>
        </div>
        <div className="footer-item">
          <div className="bold-text">Around the Web</div>
          <div className="social-icons">
            <span
              onClick={() =>
                window.open(
                  props.data.facebook || "https://www.facebook.com/RCFCNorthII",
                  "_blank"
                )
              }
            >
              <FaFacebook fontSize={30} />
            </span>
            <span
              onClick={() =>
                window.open(
                  props.data.youtube ||
                    "https://www.youtube.com/@rcfcnorthiiskuast-k7569",
                  "_blank"
                )
              }
            >
              <FaYoutube fontSize={30} />
            </span>
            {/* <span
              onClick={() =>
                window.open(
                  props.data.twitter || "https://twitter.com",
                  "_blank"
                )
              }
            >
              <FaTwitter fontSize={30} />
            </span> */}
          </div>
        </div>
      </div>

      <div class="footer-info">
        <div class="copyright-info">
          <span className="font10">
            © RCFC NR II {new Date().getFullYear()}, All Rights Reserved
          </span>
        </div>
        <div class="company-info">
          <a href="http://bracecodes.in">
            <span class="design-and-mantain font10">
              Designed and Maintained with Love By Bracecodes Software Solutions
              Pvt. Ltd.
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
